export const applicationProcedureData = [
    {
        lang: 'en-us',
        step1: 'Step 1: Initial consultation based on the student\'s academic history and future academic goals',
        step2: 'Step 2: Complete and sign the Application Form',
        step3: 'Step 3: Application materials (GEC application form, one piece of government issued ID and an official transcript) must be scanned and emailed to admissions@gecglobal.ca',
        step4: 'Step 4: You will be contacted shortly for course registration and on payment instruction. After payment is received, you will receive your enrollment notice and can start online learning!'
    },
    {
        lang: 'zh',
        step1: '第1步：根据学生的学术经验和未来的学术目标进行初步咨询',
        step2: '第2步：填写并签署申请表',
        step3: '第3步：申请资料（GEC 申请表、一份政府签发的身份证件和一份正式成绩单）必须扫描并透过电子邮件发送至admissions@gecglobal.ca',
        step4: '第 4 步：我们很快就会与您联系，以提供课程注册和付款说明。收到付款后，您将收到入学通知书并可以开始线上学习！ '
    },
    {
        lang: 'zh-hk',
        step1: '第1步：根據學生的學術經驗和未來的學術目標進行初步諮詢',
        step2: '第2步：填寫並簽署申請表',
        step3: '第3步：申請資料（GEC 申請表、一份政府簽發的身份證件和一份正式成績單）必須掃描並透過電子郵件發送至admissions@gecglobal.ca',
        step4: '第 4 步：我們很快就會與您聯繫，以提供課程註冊和付款說明。收到付款後，您將收到入學通知書並可以開始線上學習！'
    },
]

export const applicationFAQData = [
    {
        lang: 'en-us',
        content: [
            {
                question: 'How long does a course take in general?',
                answer: 'If a student choose to register courses with GEC, they can study at their own pace. Students generally have up to 12 months to complete a course although most of them complete a course in 4-6 weeks.'
            },
            {
                question: 'What learning support will I have? Can you provide one-on-one guidance if needed?',
                answer: 'The faculty of GEC World Academy consists of professional educators who are fully certified and licensed in the province of Ontario. They are experts in their subject areas as both course writers and instructors and relate well to secondary school students. Students can contact course teachers for academic counselling through GEC’s learning management platform. Students in GEC are always feeling supported.'
            },
            {
                question: 'How much are the tuition fees?',
                answer: 'Please contact our admission department (admissions@gecglobal.ca) for further information.'
            },
            {
                question: 'What is a prerequisite course? Do I need to complete it first before I register? Can a prerequisite course be waived?',
                answer: 'A prerequisite course is the course a student must complete prior to taking the next level of the subject. Prerequisite requirements are outlined by the Ministry of Education in order to ensure that students are properly prepared to take a course at the next higher level. It is possible that the prerequisite could be waived as determined by course equivalency standards if a student has taken a similar course in a different country or province. Please send us your transcripts/Ontario Student Transcript (OST)/Report Card and we can determine whether or not the prerequisite could be waived for you.'
            },
        ]
    },
    {
        lang: 'zh',
        content: [
            {
                question: '一门课程通常需要多长时间？',
                answer: '如果学生选择在 GEC 注册课程，他们可以按照自己的节奏学习。学生通常有长达 12 个月的时间来完成一门课程，尽管他们中的大多数人会在 4-6 周内完成一门课程。'
            },
            {
                question: '我会得到什么学习支持？如果需要，你能提供一对一指导吗？',
                answer: 'GEC的师资队伍由在安大略省获得全面认证和许可的专业教育工作者组成。他们是课程编写者和讲师，是各自学科领域的专家，与中学生关系良好。学生可以通过 GEC 的学习管理平台联系课程老师进行学术咨询。GEC 的学生总是能感受到支持。'
            },
            {
                question: '学费是多少？',
                answer: '请联系我们的招生部门 (admissions@gecglobal.ca) 了解更多信息。'
            },
            {
                question: '什么是先修课程？我需要先完成它才能注册吗？可以免除先修课程吗？',
                answer: '先修课程是学生在学习下一级别科目之前必须完成的课程。教育部概述了先修课程的要求，以确保学生做好充分准备学习下一级别课程。如果学生在其他国家或省份修过类似课程，则可以根据课程等效标准免除先修课程。请将您的成绩单/安大略省学生成绩单 (OST)/成绩单发送给我们，我们可以确定是否可以免除您的先修课程。'
            },
        ]
    },
    {
        lang: 'zh-hk',
        content: [
            {
                question: '一門課程一般需要多久？',
                answer: '如果學生選擇在 GEC 注册課程，他們可以按照自己的進度學習。學生通常有長達 12 個月的時間來完成課程，儘管他們中的大多數人在 4-6 週內完成課程'
            },
            {
                question: '我將獲得什麼學習支援？”如果需要，您可以提供一對一的指導嗎？',
                answer: 'GEC的師資隊伍由在安大略省獲得全面認證和許可的專業教育工作者組成。作為課程作者和講師，他們都是各自學科領域的專家，並且與中學生關係良好。學生可以透過GEC的學習管理平台聯繫課程老師進行學業諮詢。 GEC 的學生總是感到受到支持。'
            },
            {
                question: '學費是多少？',
                answer: '請聯絡我們的招生部門 (admissions@gecglobal.ca) 以了解更多資訊。'
            },
            {
                question: '什麼是先修課程？我需要在註冊之前先完成它嗎？可以放棄先修課程嗎？',
                answer: '先修課程是學生在學習下一級別的科目之前必須完成的課程。教育部概述了先決條件，以確保學生為學習更高級別的課程做好充分準備。如果學生在不同的國家或省份修讀過類似的課程，則根據課程等效標準確定，可以免除先決條件。請將您的成績單/安大略省學生成績單（OST）/成績單寄給我們，我們可以確定是否可以為您免除先決條件。'
            },
        ]
    },
]
