export const introContent = [
    {
        lang: 'en-us',
        content: {
            header: 'Empower Your Future',
            descs: [
                { text: 'GEC World Academy (GEC) is in the process of being validated by the Ministry of Education.' },
                { text: 'Provides 6 courses, all delivered by experienced Ontario Certified Teachers' },
                { text: 'Provides timely feedback to ensure students’ continued success in academia' },
                { text: 'Brand-new designed Global Learning Management System, 24/7 access from anywhere in the world' },
            ]
        },
    },
    {
        lang: 'zh',
        content: {
            header: '助力您的未来',
            descs: [
                { text: '全球教育学院（GEC）是正在接受教育部的验证' },
                { text: '提供6个课程，均由经验丰富的安大略省认证教师授课' },
                { text: '提供及时反馈，确保学生在学术界继续取得成功' },
                { text: '全新设计的全球学习管理系统，可从世界任何地方全天候访问' },
            ]
        },
    },
    {
        lang: 'zh-hk',
        content: {
            header: '賦能您的未來',
            descs: [
                { text: '全球教育學院（GEC）是正在接受教育部的驗證' },
                { text: '提供6個課程，全部由經驗豐富的安大略省認證教師授課' },
                { text: '提供及時回饋，確保學生在學術界持續取得成功' },
                { text: '全新設計的全球學習管理系統，24/7 全天候存取' },
            ]
        },
    }
]

export const offerContent = [
    {
        lang: 'en-us',
        content: {
            header: 'Improve Your Learning Experience with us',
            descs: [
                {
                    title: 'Blended Learning Program with highly effective learning environment',
                    text: 'GEC takes full advantage of today’s fast-paced world through our innovative learning management platform. Blending effective multimedia resources with traditional in-class instructions allows for our curriculum to keep course content current and engaging for all students.'
                },
                {
                    title: 'Global Learning Management System',
                    text: 'As a unique private high school, GEC offers an education tailored to the needs of local and international students with its dynamic learning management system. The system was developed by professional educators, with aims at providing an innovative and interactive learning experience for our students which is far more engaging than simply just reading textbooks.'
                },
                {
                    title: 'Comprehensive Student Support',
                    text: 'Our faculty are experts in their subject areas as both course writers and instructors and can relate well to secondary school students. They are invaluable assets to student success by providing one-on-one guidance when needed. With a wealth of experience from teaching across Ontario and internationally, our teachers can anticipate most learning needs and provide the necessary support to ensure our students\' continued success in academia.'
                },
            ]
        },
    },
    {
        lang: 'zh',
        content: {
            header: '与我们一起改善您的学习体验',
            descs: [
                {
                    title: '混合式学习计划，具有高效的学习环境',
                    text: 'GEC 通过我们创新的学习管理平台充分利用了当今快节奏的世界。将有效的多媒体资源与传统的课堂教学相结合，使我们的课程能够保持课程内容的时效性，并吸引所有学生。'
                },
                {
                    title: '全球学习管理系统',
                    text: '作为一所独特私立高中，GEC 凭借其动态学习管理系统提供针对本地和国际学生需求的定制教育。该系统由专业教育工作者开发，旨在为我们的学生提供创新和互动的学习体验，这比仅仅阅读教科书更有吸引力。'
                },
                {
                    title: '全面的学生支持',
                    text: '我们的教师是课程编写者和讲师，都是各自学科领域的专家，可以很好地与中学生建立联系。他们在需要时提供一对一的指导，是学生成功的宝贵财富。我们的教师在安大略省和国际上拥有丰富的教学经验，可以预见大多数学习需求并提供必要的支持，以确保我们的学生在学术上继续取得成功。'
                },
            ]
        },
    },
    {
        lang: 'zh-hk',
        content: {
            header: '與我們一起改善您的學習體驗',
            descs: [
                {
                    title: '具有高效學習環境的混合學習計劃',
                    text: 'GEC 透過我們創新的學習管理平台充分利用當今快節奏的世界。將有效的多媒體資源與傳統的課堂教學相結合，使我們的課程能夠保持課程內容的最新性並吸引所有學生。'
                },
                {
                    title: '全球學習管理系統',
                    text: '作為一所獨特私立高中，GEC 憑藉其動態學習管理系統提供適合本地和國際學生需求的教育。該系統由專業教育工作者開發，旨在為我們的學生提供創新和互動的學習體驗，這比僅僅閱讀教科書更具吸引力'
                },
                {
                    title: '全面的學生支持',
                    text: '我們的教師都是各自學科領域的專家，既是課程作者又是講師，並且能夠與中學生建立良好的關係。他們在需要時提供一對一的指導，是學生成功的寶貴資產。憑藉在安大略省和國際上的豐富教學經驗，我們的教師可以預測大多數學習需求並提供必要的支持，以確保我們的學生在學術界持續取得成功。'
                },
            ]
        },
    }
]


export const floorContent = [
    {
        lang: 'en-us',
        content: {
            header: 'How Do I Sign Up for a course?',
            descs: [
                { text: 'Complete the Application Form' },
                { text: 'Submit all application materials by email' },
                { text: 'You will be contacted shortly on the courses registration' },
                { text: 'You will have access to your course(s) immediately!' },
            ]
        },
    },
    {
        lang: 'zh',
        content: {
            header: '如何报名参加课程？',
            descs: [
                { text: '填写申请表' },
                { text: '通过电子邮件提交所有申请材料' },
                { text: '我们会尽快与您联系，告知您课程注册事宜' },
                { text: '您将可以立即访问您的课程！' },
            ]
        },
    },
    {
        lang: 'zh-hk',
        content: {
            header: '我如何註冊課程？',
            descs: [
                { text: '填寫申請表' },
                { text: '透過電子郵件提交所有申請資料' },
                { text: '我們很快就會就課程註冊事宜與您聯繫' },
                { text: '您將可以立即存取您的課程！' },
            ]
        },
    }
]
