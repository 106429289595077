














































import { Component, Vue } from 'vue-property-decorator';
import { courseSelectData } from '@/static/data/course';

@Component({
  name: 'sampleLesson',
})
export default class extends Vue {
  public selectID: number = 71;
  public selectSeq: number = 2;
  public courseList: any[] = [];
  get courseInfo() {
    return courseSelectData.filter((v: any) => (v.id === this.selectID))[0];
  }
  public created() {
    let id = +this.$route.params.id;
    let seq = 0;
    this.courseList = courseSelectData.filter((v: any) => (v.video_url !== ''));
    if ((id === undefined) || (isNaN(id))) {
      id = 71;
      seq = 2;
    } else {
      const courseInfo = this.courseList.filter((v: any) => (v.id === id));
      seq = courseInfo[0].seq;
    }
    this.selectID = id;
    this.selectSeq = seq;
  }
  public goPrev() {
    if (this.courseList.length > 1) {
      this.selectSeq = this.selectSeq - 1;
      if (this.selectSeq <= 0) {
        const courseInfo: any[] = this.courseList.filter((v: any) => (v.seq === this.selectSeq));
        if (courseInfo && (courseInfo.length > 0)) {
          this.selectID = courseInfo[0].id;
        }
      }
    }
  }
  public goNext() {
    if (this.courseList.length > 1) {
      this.selectSeq = this.selectSeq + 1;
      let courseInfo: any[] = this.courseList.filter((v: any) => (v.seq === this.selectSeq));
      if (courseInfo && (courseInfo.length > 0)) {
        this.selectID = courseInfo[0].id;
      } else {
        this.selectSeq = 1;
        courseInfo = this.courseList.filter((v: any) => (v.seq === this.selectSeq));
        if (courseInfo) {
          this.selectID = courseInfo[0].id;
        }
      }
    }
  }
  public goCourseAll() {
    window.location.href = '../CourseList';
  }
}
