


















































import { Component, Vue } from 'vue-property-decorator';
import { courseSelectData } from '@/static/data/course';

@Component({
  name: 'sampleLesson',
})
export default class extends Vue {
  public selectID: number = 1;
  get courseInfo() {
    return courseSelectData.filter((v: any) => (v.id === this.selectID))[0];
  }
  get courseList() {
    return courseSelectData;
  }
  public created() {
    let id = +this.$route.params.id;
    if ((id === undefined) || (isNaN(id))) {
      id = 1;
    }
    this.selectID = id;
  }
  public goPrev() {
    this.selectID = this.selectID - 1;
    if (this.selectID <= 0) {
      this.selectID = courseSelectData[courseSelectData.length-1].id;
    }
  }
  public goNext() {
    this.selectID = this.selectID + 1;
    if (this.selectID > courseSelectData.length) {
      this.selectID = 1;
    }
  }
  public goApplication() {
    window.location.href = '../ApplicationProcedure';
  }
  public goCourseAll() {
    window.location.href = '../CourseList';
  }
}
