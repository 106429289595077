



































































































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { principalContent, teacherContent } from "@/static/data/about";

@Component({
  name: "about"
})
export default class extends Vue {
  get isMobile() {
    return AppModule.language;
  }
  get principalInfo() {
    return principalContent.filter(
      (v: any) => v.lang === AppModule.language.toLowerCase()
    )[0].content;
  }
  get teacherInfo() {
    return teacherContent.filter(
      (v: any) => v.lang === AppModule.language.toLowerCase()
    )[0].content;
  }
  get isPrincipal() {
    const sectionName = this.$router.currentRoute.path.substring(
      this.$router.currentRoute.path.lastIndexOf("/") + 1
    );
    if (sectionName.toLowerCase() === "principal") {
      return true;
    } else {
      return false;
    }
  }
  public goSampleLesson() {
    window.location.href = "../sampleLesson";
  }
}
