



































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import {
  applicationProcedureData,
  applicationFAQData
} from "@/static/data/application";

@Component({
  name: "applicationProcedure"
})
export default class extends Vue {
  public selectMenu = "procedure";
  public docRefresh = "";
  get procedureData() {
    return applicationProcedureData.filter(
      (v: any) => v.lang === AppModule.language.toLowerCase()
    )[0];
  }
  get faqList() {
    return applicationFAQData.filter(
      (v: any) => v.lang === AppModule.language.toLowerCase()
    )[0].content;
  }
  public mounted() {
    this.docRefresh = Date.now().toString();
  }
  public created() {
    this.selectMenu = "procedure";
    switch (this.$router.currentRoute.path.toLowerCase()) {
      case "/applicationprocedure":
        this.selectMenu = "procedure";
        break;
      case "/applicationformspolicy":
        this.selectMenu = "policy";
        break;
      case "/applicationfaq":
        this.selectMenu = "faq";
        break;
      default:
        this.selectMenu = "procedure";
        break;
    }
  }

  public switchMenu(menu: string) {
    this.selectMenu = menu;
  }
}
