import i18n from '@/lang';
import axios from 'axios';
import { MessageBox } from 'element-ui';
import { UserModule } from '@/store/modules/user';

// process.env.VUE_APP_BASE_API 可在根目录下新建.env.development与.env.production文件
// 分别配置开发环境与生产环境的请求基路径
// 如 VUE_APP_BASE_API = '192.168.1.129:6666'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.fkPlatformType = 'get_gec';
axios.defaults.headers.languagecode = i18n.locale;
axios.defaults.timeout = 300000;

export interface ResponseData {
  /** code
   * "0001","缺少参数"
   * "0002","无效参数"
   * "0003","无效签名"
   * "0004","验证失败"
   * "0005","服务不可用"
   * "0006","会话已失效"
   * "0007","没权限"
   * "1001","禁止访问"
   * "1002","请求错误"
   * "1003","请求成功"
   * "1004","服务器错误"
   * "1005","访问外部服务错误"
   * "1006","网关超时"
   * "1007","feign调用失败"
   */
  code: string;
  data: {};
  datas: [];
  success: boolean;
  message: string;
}

// 登录超时弹窗
export function loginTimeoutMessageBox(confirmText: string) {
  MessageBox.confirm(
    confirmText,
    i18n.t('index.errorMsg') as string,
    {
      confirmButtonText: i18n.t('index.confirm') as string,
      type: 'warning',
      showClose: false,
      closeOnClickModal: false,
      showCancelButton: false,
  }).then(() => {
    // UserModule.ResetToken();
    location.reload();
  })
}

export const transformRequest = [(data: any) => {
  let ret = '';
  for (const it in data) {
    if (it) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    }
  }
  return ret;
}];

export default axios;
