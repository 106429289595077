export const courseType = [
    { id: 1, value: 'all', name: 'All', nameChn: '全部' },
    { id: 4, value: 'businessStudies', name: 'Business Studies', nameChn: '商業研究' },
    { id: 5, value: 'canadianNWorldStudies', name: 'Canadian and World Studies', nameChn: '加拿大與世界研究' },
    { id: 8, value: 'English', name: 'English', nameChn: '英語' },
    { id: 13, value: 'grade12', name: 'Grade 12', nameChn: '12年級' },
    { id: 18, value: 'math', name: 'Math', nameChn: '數學' },
]

export const courseData = [
    { id: 70, img: require('../../assets/image/resource/course70.png') },
    { id: 71, img: require('../../assets/image/resource/course71.png') },
    { id: 72, img: require('../../assets/image/resource/course72.png') },
    { id: 107, img: require('../../assets/image/resource/course107.png') },
    { id: 106, img: require('../../assets/image/resource/course106.png') },
    { id: 109, img: require('../../assets/image/resource/course109.png') }
]


export const courseSelectData = [
    {
        id: 70,
        seq: 1,
        course_code: 'ENG4U',
        title: 'Grade 12 English',
        img: require('../../assets/image/resource/course70.png'),
        desc: 'English, Grade 12, University Preparation. This course emphasizes consolidation of literacy, critical thinking, and communication skills. Students will analyse a range of challenging texts from various time periods, countries, and cultures; write analytical and argumentative essays and a major paper for an independent literary research project; and apply key concepts to analyse media works. An important focus will be on understanding academic language and using it coherently and confidently in discussion and argument.',
        prerequisite: 'Grade 11 English',
        course_type: 'University(U)',
        course_desc: 'This course emphasizes consolidation of literacy, critical thinking, and communication skills. Students will analyse a range of challenging texts from various time periods, countries, and cultures; write analytical and argumentative essays and a major paper for an independent literary research project; and apply key concepts to analyse media works. An important focus will be on understanding academic language and using it coherently and confidently in discussion and argument.',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. <br/>Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: '',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course70_summary_unit_timelines.jpg'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course70_curriculum.jpg'),
        video_url: 'https://cosmoodle.geteducation.net/videos/ENG4UGrade12English.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'Y',
        course_type_math: 'N',
        course_type_business: 'N',
        course_type_canadian_world_studies: 'N'
    },
    {
        id: 71,
        seq: 2,
        course_code: 'MHF4U',
        title: 'Grade 12 Advanced Functions',
        img: require('../../assets/image/resource/course71.png'),
        desc: 'Math, Grade 12, Advanced Functions, University Preparation. This course extends students’ experience with functions. Students will investigate the properties of polynomial, rational, logarithmic, and trigonometric functions; develop techniques for combining functions; broaden their understanding of rates of change; and develop facility in applying these concepts and skills. Students will also refine their use of the mathematical processes necessary for success in senior mathematics. This course is intended both for students taking the Calculus and Vectors course as a prerequisite for a university program and for those wishing to consolidate their understanding of mathematics before proceeding to any one of a variety of university programs.',
        prerequisite: 'Grade 11 Functions',
        course_type: 'University(U)',
        course_desc: 'This course extends students’ experience with functions. Students will investigate the properties of polynomial, rational, logarithmic, and trigonometric functions; develop techniques for combining functions; broaden their understanding of rates of change; and develop facility in applying these concepts and skills. Students will also refine their use of the mathematical processes necessary for success in senior mathematics. This course is intended both for students taking the Calculus and Vectors course as a prerequisite for a university program and for those wishing to consolidate their understanding of mathematics before proceeding to any one of a variety of university programs.',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: 'Note: This course is entirely online and does not require or rely on any textbook.<br/>1) A scanner, smart phone camera, or similar device to upload handwritten or hand-drawn work<br/>2) A digital video camera, a web camera, or similar device to record and upload video recordings<br/>3) Room to move about when planning a short dramatic presentation and experimenting with some dance steps',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course70_summary_unit_timelines.jpg'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course70_curriculum.jpg'),
        video_url: 'https://cosmoodle.geteducation.net/videos/MHF4UGrade12AdvancedFunctions.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'N',
        course_type_math: 'Y',
        course_type_business: 'N',
        course_type_canadian_world_studies: 'N'
    },
    {
        id: 72,
        seq: 3,
        course_code: 'MCV4U',
        title: 'Grade 12 Calculus and Vectors',
        img: require('../../assets/image/resource/course72.png'),
        desc: 'This course builds on students’ previous experience with functions and their developing understanding of rates of change. Students will solve problems involving geometric and algebraic representations of vectors and representations of lines and planes in three-dimensional space; broaden their understanding of rates of change to include the derivatives of polynomial, sinusoidal, exponential, rational, and radical functions; and apply these concepts and skills to the modelling of real-world relationships. Students will also refine their use of the mathematical processes necessary for success in senior mathematics. This course is intended for students who choose to pursue careers in fields such as science, engineering, economics, and some areas of business, including those students who will be required to take a university-level calculus, linear algebra, or physics course.',
        prerequisite: 'Grade 12 Advanced Functions',
        course_type: 'University(U)',
        course_desc: 'This course builds on students’ previous experience with functions and their developing understanding of rates of change. Students will solve problems involving geometric and algebraic representations of vectors and representations of lines and planes in three-dimensional space; broaden their understanding of rates of change to include the derivatives of polynomial, sinusoidal, exponential, rational, and radical functions; and apply these concepts and skills to the modelling of real-world relationships. Students will also refine their use of the mathematical processes necessary for success in senior mathematics. This course is intended for students who choose to pursue careers in fields such as science, engineering, economics, and some areas of business, including those students who will be required to take a university-level calculus, linear algebra, or physics course.',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. <br/>Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: '',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course72_summary_unit_timelines.jpg'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course72_curriculum.jpg'),
        video_url: 'https://cosmoodle.geteducation.net/videos/MCV4UGrade12CalculusandVectors.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'N',
        course_type_math: 'Y',
        course_type_business: 'N',
        course_type_canadian_world_studies: 'N'
    },
    {
        id: 107,
        seq: 4,
        course_code: 'BOH4M',
        title: 'Grade 12 Business Leadership',
        img: require('../../assets/image/resource/course107.png'),
        desc: 'This course focuses on the development of leadership skills used in managing a successful business. Students will analyse the role of a leader in business, with a focus on decision making, management of group dynamics, workplace stress and conflict, motivation of employees, and planning. Effective business communication skills, ethics, and social responsibility are also emphasized.',
        prerequisite: 'N/A',
        course_type: 'University/College(M)',
        course_desc: 'This course focuses on the development of leadership skills used in managing a successful business. Students will analyse the role of a leader in business, with a focus on decision making, management of group dynamics, workplace stress and conflict, motivation of employees, and planning. Effective business communication skills, ethics, and social responsibility are also emphasized.',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. <br/>Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: '',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course107_summary_unit_timelines.png'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course107_curriculum.png'),
        video_url: 'https://cosmoodle.geteducation.net/videos/BOH4MGrade12BusinessLeadership.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'N',
        course_type_math: 'N',
        course_type_business: 'Y',
        course_type_canadian_world_studies: 'N'
    },
    {
        id: 106,
        seq: 5,
        course_code: 'BBB4M',
        title: 'Grade 12 International Business Fundamentals',
        img: require('../../assets/image/resource/course106.png'),
        desc: 'International Business Fundamentals - BBB4M course provides an overview of the importance of international business and trade in the global economy and explores the factors that influence success in international markets. Students will learn about the techniques and strategies associated with marketing, distribution, and managing international business effectively. This course prepares students for postsecondary programs in business, including international business, marketing, and management.',
        prerequisite: '',
        course_type: 'University/College(M)',
        course_desc: 'International Business Fundamentals - BBB4M course provides an overview of the importance of international business and trade in the global economy and explores the factors that influence success in international markets. Students will learn about the techniques and strategies associated with marketing, distribution, and managing international business effectively. This course prepares students for postsecondary programs in business, including international business, marketing, and management.',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. <br/>Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: '',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course106_summary_unit_timelines.png'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course106_curriculum.png'),
        video_url: 'https://cosmoodle.geteducation.net/videos/BBB4MRade12InternationalBusinessFundamentals.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'N',
        course_type_math: 'N',
        course_type_business: 'Y',
        course_type_canadian_world_studies: 'N'
    },
    {
        id: 109,
        seq: 6,
        course_code: 'CIA4U',
        title: 'Grade 12 Analysing Current Economic Issues',
        img: require('../../assets/image/resource/course109.png'),
        desc: 'This course examines current Canadian and international economic issues, developments, policies, and practices from diverse perspectives. Students will explore the decisions that individuals and institutions, including governments, make in response to economic issues such as globalization, trade agreements, economic inequalities, regulation, and public spending. Students will apply the concepts of economic thinking and the economic inquiry process, as well as economic models and theories, to investigate, and develop informed opinions about, economic trade-offs, growth, and sustainability and related economic issues.',
        prerequisite: 'Any U/M courses*',
        course_type: 'University(U)',
        course_desc: 'This course examines current Canadian and international economic issues, developments, policies, and practices from diverse perspectives. Students will explore the decisions that individuals and institutions, including governments, make in response to economic issues such as globalization, trade agreements, economic inequalities, regulation, and public spending. Students will apply the concepts of economic thinking and the economic inquiry process, as well as economic models and theories, to investigate, and develop informed opinions about, economic trade-offs, growth, and sustainability and related economic issues. ',
        course_summary_unit_timelines: 'Below is the suggested sequence of course unit delivery as well as the recommended number of hours to complete the respective unit. <br/>Note: This course is entirely online and does not require or rely on any textbook.',
        course_summary_unit_timelines_remark: '',
        course_summary_unit_timelines_img: require('../../assets/image/resource/course109_summary_unit_timelines.png'),
        course_curriculum: '',
        course_curriculum_img: require('../../assets/image/resource/course109_curriculum.png'),
        video_url: 'https://cosmoodle.geteducation.net/videos/CIA4UGrade12AnalysingCurrentEconomicIssues.mp4',
        course_type_grade12: 'Y',
        course_type_eng: 'N',
        course_type_math: 'N',
        course_type_business: 'N',
        course_type_canadian_world_studies: 'Y'
    },
]

