export const courseFeatureData = [
    {
        lang: 'en-us',
        content: [
            {
                name: 'Interactive',
                img: require('../../assets/image/resource/interactive.jpg'),
                desc: 'Varied quizzes and assessments to demonstrate learning'
            },
            {
                name: 'Convenient',
                img: require('../../assets/image/resource/convenient.jpg'),
                desc: 'Start your learning at anytime and anywhere'
            },
            {
                name: 'Easy to Use',
                img: require('../../assets/image/resource/quality.jpg'),
                desc: 'Brand new designed Learning Management System with clear guidance'
            },
            {
                name: 'Supportive',
                img: require('../../assets/image/resource/supportive.jpg'),
                desc: 'Ontario Certified Teachers (OCT) available with office hours to support students'
            },
        ]
    },
    {
        lang: 'zh',
        content: [
            {
                name: '互动',
                img: require('../../assets/image/resource/interactive.jpg'),
                desc: '多种测验和评估来展示学习成果'
            },
            {
                name: '方便',
                img: require('../../assets/image/resource/convenient.jpg'),
                desc: '随时随地开始学习'
            },
            {
                name: '易于使用',
                img: require('../../assets/image/resource/quality.jpg'),
                desc: '全新设计的学习管理系统，提供清晰的指导'
            },
            {
                name: '支持',
                img: require('../../assets/image/resource/supportive.jpg'),
                desc: '安大略省认证教师 (OCT) 可在办公时间为学生提供支持'
            },
        ]
    },
    {
        lang: 'zh-hk',
        content: [
            {
                name:'互動',
                img: require('../../assets/image/resource/interactive.jpg'),
                desc:'展示學習情況的各種測驗和評估'
            },
            {
                name: '方便',
                img: require('../../assets/image/resource/convenient.jpg'),
                desc: '隨時隨地開始學習'
            },
            {
                name:'易於使用',
                img: require('../../assets/image/resource/quality.jpg'),
                desc:'全新設計的學習管理系統，具有明確的指導'
            },
            {
                name:'支援',
                img: require('../../assets/image/resource/supportive.jpg'),
                desc: '安大略省認證教師 (OCT) 可在辦公時間為學生提供支援'
            },
        ]
    }
]
