
export const principalContent = [
    {
        lang: 'en-us',
        content: {
            header: '"GEC World Academy is your pathway to success!"',
            descs: [
                { text: 'GEC World Academy (GEC) has designed the courses with clear explanations and expectations. Students will achieve their success through lessons that include the use of various multimedia platforms designed to engage all learners. The hands-on experiences within the lessons assist students\' understanding and enable them to demonstrate a deeper understanding through the assignments and tests. ' },
                { text: 'Mr. Brennan has over 25 years’ experience as an educator, with fifteen plus years as an administrator in both the Canadian private and public systems. Mr. Brennan has been recognized for his contributions and leadership in international education by the Ministry of Education. Having been invited to by the Ministry of Education and some of Canada\'s top universities to be a part of many educational forums and panels, his leadership, communication, and management skills are respected by his colleagues. ' },
                { text: 'Mr. Brennan believes that every student can be successful. It is this belief that has been the driving force with the creation of GEC\'s courses. The lessons and activities embedded in each course, allow students to communicate their knowledge through a variety of means. Students and parents have commented on the caring nature of the teachers, which is a reflection of Mr. Brennan\'s beliefs of combining a supportive learning environment with a dynamic and interactive multi-media platform. Mr. Brennan will be a part of many student-teacher conversations which enforces the confidence in students that Mr. Brennan will always support them.  ' },
            ],
            name: 'Mr. Ted Brennan',
            positionTitle1: 'Principal of GEC World Academy (GEC)',
            positionTitle2: 'Master of Education - Educational Leadership and Administration',
        },
    },
    {
        lang: 'zh',
        content: {
            header: '"环球教育学院是您成功的途径"',
            descs: [
                { text: 'GEC环球教育学院 (GEC) 设计的课程具有明确的解释和期望。学生将透过课程取得成功，其中包括使用旨在吸引所有学习者的各种多媒体平台。课程中的实务经验有助于学生的理解，并使他们能够透过作业和测验表现出更深入的理解。' },
                { text: '特德布伦南先生拥有超过 25 年的教育经验，其中有 15 年以上在加拿大私立和公立系统担任管理人员的经验。特德布伦南先生因其在国际教育领域的贡献和领导力而受到教育部的认可。曾受教育部和加拿大一些顶尖大学邀请参加许多教育论坛和小组，他的领导能力、沟通和管理能力受到同事的尊重。' },
                { text: '特德布伦南先生相信每个学生都可以成功。正是这种信念成为 GEC 课程创建的驱动力。每门课程中嵌入的课程和活动允许学生透过各种方式交流他们的知识。学生和家长对老师的关怀给予了评价，这反映了特德布伦南先生将支持性学习环境与动态互动多媒体平台结合的信念。特德布伦南先生将参与许多师生对话，这增强了学生对特德布伦南先生将始终支持他们的信心。' },
            ],
            name: '特德布伦南先生',
            positionTitle1: 'GEC环球教育学院校长',
            positionTitle2: '教育硕士 - 教育领导与管理',
        },
    },
    {
        lang: 'zh-hk',
        content: {
            header: '"環球教育學院是您成功的途徑！"',
            descs: [
                { text: 'GEC環球教育學院 (GEC) 設計的課程具有明確的解釋和期望。學生將透過課程取得成功，其中包括使用旨在吸引所有學習者的各種多媒體平台。課程中的實務經驗有助於學生的理解，並使他們能夠透過作業和測驗表現出更深入的理解。' },
                { text: '特德布倫南先生擁有超過 25 年的教育經驗，其中有 15 年以上在加拿大私立和公立系統擔任管理人員的經驗。特德布倫南先生因其在國際教育領域的貢獻和領導力而受到教育部的認可。曾受教育部和加拿大一些頂尖大學邀請參加許多教育論壇和小組，他的領導能力、溝通和管理能力受到同事的尊重。' },
                { text: '特德布倫南先生相信每個學生都可以成功。正是這種信念成為 GEC 課程創建的驅動力。每門課程中嵌入的課程和活動允許學生透過各種方式交流他們的知識。學生和家長對老師的關懷給予了評價，這反映了特德布倫南先生將支持性學習環境與動態互動多媒體平台結合的信念。特德布倫南先生將參與許多師生對話，這增強了學生對特德布倫南先生將始終支持他們的信心。' },
            ],
            name: '特德布倫南先生',
            positionTitle1: 'GEC環球教育學院校長',
            positionTitle2: '教育碩士 - 教育領導與管理',
        },
    }
]


export const teacherContent = [
    {
        lang: 'en-us',
        content: [
            {
                name: 'Ms. Samantha F.',
                info: [
                    { qualify: 'Bachelor of Arts Honours (Double Major in English and Human Geography) & Bachelor of Education, University of Toronto' },
                    { qualify: 'OCT - Senior English and Geography' },
                    { qualify: '10+ years being ESL and Academic Teacher in both private and public schools across Ontario' },
                ],
                img: require('../../assets/image/common/TeacherSamanthaF.png')
            },
            {
                name: 'Mrs. Janice K.',
                info: [
                    { qualify: 'Bachelor of Arts-Social Sciences Honours, University of Toronto' },
                    { qualify: 'Bachelor of Education, (Social Science)' },
                    { qualify: '20+ years teaching Social Sciences, ESL, and English in both public and private schools across Ontario' },
                    { qualify: 'OCT - Senior English, History' },
                ],
                img: require('../../assets/image/common/TeacherJaniceK.png')
            },
            {
                name: 'Mr. David L.',
                info: [
                    { qualify: 'Bachelor of Science Honours (Major in Biology) & Bachelor of Education, York University' },
                    { qualify: 'Intermediate/Senior Division Teachable Subjects: Biology (1st) and Mathematics (2nd)' },
                    { qualify: 'OCT - Junior and Senior Sciences and Mathematics' },
                ],
                img: require('../../assets/image/common/TeacherDavidL.png')
            }
        ],
    },
    {
        lang: 'zh',
        content: [
            {
                name: '萨曼莎女士',
                info: [
                    { qualify: '多伦多大学荣誉文学士（英语和人文地理学双学位）和教育学士' },
                    { qualify: '高级英语和地理' },
                    { qualify: '在安大略省的私立和公立学校担任 ESL 和学术教师超过 10 年' },
                ],
                img: require('../../assets/image/common/TeacherSamanthaF.png')
            },
            {
                name: '珍妮丝女士',
                info: [
                    { qualify: '多伦多大学社会科学荣誉文学士' },
                    { qualify: '教育学士（社会科学）' },
                    { qualify: '在安大略省的公立和私立学校教授社会科学、ESL 和英语 20 多年' },
                    { qualify: 'OCT - 高级英语、历史' },
                ],
                img: require('../../assets/image/common/TeacherJaniceK.png')
            },
            {
                name: '大卫先生',
                info: [
                    { qualify: '约克大学荣誉理学士（生物学专业）与教育学士' },
                    { qualify: '中/高年级可教科目：生物学（第一）和数学（第二）' },
                    { qualify: '华侨城 - 国中与高中科学与数学' },
                ],
                img: require('../../assets/image/common/TeacherDavidL.png')
            }
        ],
    },
    {
        lang: 'zh-hk',
        content: [
            {
                name: '薩曼莎女士',
                info: [
                    { qualify: '多倫多大學榮譽文學士（英語和人文地理學雙學位）和教育學士' },
                    { qualify: '高級英語和地理' },
                    { qualify: '在安大略省的私立和公立學校擔任 ESL 和學術教師超過 10 年 ' },
                ],
                img: require('../../assets/image/common/TeacherSamanthaF.png')
            },
            {
                name: '珍妮絲女士',
                info: [
                    { qualify: '多倫多大學社會科學榮譽文學士' },
                    { qualify: '教育學士（社會科學）' },
                    { qualify: '在安大略省的公立和私立學校教授社會科學、ESL 和英語 20 多年' },
                    { qualify: 'OCT - 高級英語、歷史' },
                ],
                img: require('../../assets/image/common/TeacherJaniceK.png')
            },
            {
                name: '大衛先生',
                info: [
                    { qualify: '約克大學榮譽理學士（生物學專業）與教育學士' },
                    { qualify: '中/高年級可教科目：生物學（第一）和數學（第二）' },
                    { qualify: '華僑城 - 國中與高中科學與數學' },
                ],
                img: require('../../assets/image/common/TeacherDavidL.png')
            }
        ],
    }
]
