
































































import { Component, Vue } from 'vue-property-decorator';
import { courseSelectData, courseType } from '@/static/data/course';
import { AppModule } from '@/store/modules/app';
import { isMobile } from '@/utils/validate';


interface PaginationFilter<T = any> {
  totalResult: number,
  currentPage: number,
  showCount: number,
  totalPage: number
}

@Component({
  name: 'course',
})
export default class extends Vue {
  public courseList: any[] = [];
  public courseTypeList: any[] = [];
  public searchKeyword: string = '';
  public selectCourseType: string = '';
  public dataPagination: PaginationFilter<any> = {
    totalResult: 0,
    currentPage: 1,
    showCount: 10,
    totalPage: 1
  };
  get isMobile() {
    return isMobile();
  }
  get langEN() {
      if (AppModule.language === 'en-us') {
          return true;
      } else {
          return false;
      }
  }
  public created() {
    this.courseList = courseSelectData;
    this.courseTypeList = courseType;
  }
  public goCourse(item: any) {
    window.location.href = "../course/" + item.id;
  }
  public handleCurrentChange(currentPage: number) {
    this.dataPagination.currentPage = currentPage;
    this.handleSearch();
  }

  public handleSizeChange(pageSize: number) {
    this.dataPagination.showCount = pageSize;
    this.handleSearch();
  }
  public handleChangeKeyword(val: string) {
    this.handleSearchKeyword();
  }
  public async handleSearchKeyword() {
    this.courseList = courseSelectData.filter((v: any) => v.title.toLowerCase().match(this.searchKeyword.toLowerCase()));
  }
  public async handleSearch() {
    console.log(this.selectCourseType);
    switch(this.selectCourseType.toString()) {
      case '13':
        this.courseList = courseSelectData.filter((v: any) => v.course_type_grade12 === 'Y');
        return;
      case '8':
        this.courseList = courseSelectData.filter((v: any) => v.course_type_eng === 'Y');
        return;
      case '18':
        this.courseList = courseSelectData.filter((v: any) => v.course_type_math === 'Y');
        return;
      case '4':
        this.courseList = courseSelectData.filter((v: any) => v.course_type_business === 'Y');
        return;
      case '5':
        this.courseList = courseSelectData.filter((v: any) => v.course_type_canadian_world_studies === 'Y');
        return;
      default:
        this.courseList = courseSelectData;
        return;
    }
  }
  public goSampleLessonAll() {
    window.location.href = '../sampleLesson';
  }
  public goSampleLesson(item: any) {
    window.location.href = "../sampleLesson/" + item.id;
  }
}
