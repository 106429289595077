



































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { isMobile } from '@/utils/validate';

interface BannerItem {
  link: string,
  path: string,
  remark?: string
}
@Component({
  name: 'bannerBox'
})
export default class BannerBox extends Vue {
  @Ref('bannerRef') public bannerRef!: HTMLDivElement;
  @Prop() public list!: BannerItem[];
  @Prop({default: 'cover', required: false}) public objectFit!: string;
  public currentIndex = 0;
  public time: any;
  public bannerInfo = {
    x: 0,
    active: false,
    start: 0,
    move: 0,
    startTime: 0,
    transition: false,
    width: 0,
  }
  get isMobile() {
    return isMobile();
  }
  get bannerList() {
    const lng = this.list.length;
    if(lng <= 1) {
      return this.list;
    }
    return [this.list[lng-1]].concat(this.list, this.list[0])
  }
  public mounted() {
    const width = this.bannerRef.clientWidth;
    if (!width) {
      return;
    }
    this.bannerInfo.width = width;

    window.addEventListener('resize', this.windowSizeChange);
    if(this.list.length <= 1) {
      return;
    }
    this.bannerInfo.x = -width;
    // 自动滚动
    this.startAutoScroll();
    this.$once("hook:beforeDestroy", ()=> {
      // clearInterval(time);
      // this.time && clearInterval(this.time);
      clearInterval(this.time);
      window.removeEventListener('resize', this.windowSizeChange)
    })
  }
  public startAutoScroll() {
    // this.time && clearInterval(this.time);
    clearInterval(this.time);
    this.time = setTimeout(()=> {
      this.swipeNext();
      this.startAutoScroll()
    },4000)
  }
  public onBannerDown(e: any) {
    const domBox = e.currentTarget;
    if(this.bannerInfo.transition || !this.bannerList.length) {
      return;
    }
    domBox.addEventListener('mousemove',this.onBannerMove)
    // this.time && clearInterval(this.time);
    clearInterval(this.time);
    const start = e.clientX || (e.touches && e.touches[0].clientX)
    this.bannerInfo.active = true;
    this.bannerInfo.startTime = Date.now();
    this.bannerInfo.start = start;
  }
  public onBannerUp(e: any) {
    const domBox = e.currentTarget;
    if(!this.bannerInfo.active) {
      return;
    }
    domBox.removeEventListener('mousemove',this.onBannerMove);
    const endTime = Date.now();
    this.bannerInfo.active = false;
    const winWidth = this.bannerRef.clientWidth;
    let isSuc = false;
    if (winWidth / 3 < Math.abs(this.bannerInfo.move)) {
        isSuc = true;
    } else {
      // 计算速度 px/ms
      const v = this.bannerInfo.move / (this.bannerInfo.startTime - endTime);
      if(v > 1) {
        isSuc = true;
      }
    }
    if(this.list.length > 1 && isSuc ) {
      // 切换至上一张图
      if (this.bannerInfo.move > 0) {
        if(this.bannerInfo.x % winWidth !== 0) {
          // 说明容器宽度不一样
          const x = this.bannerInfo.x / this.bannerInfo.width
          this.bannerInfo.width = winWidth;
          this.bannerInfo.x = (x+1)*winWidth
        } else {
          this.bannerInfo.x += winWidth;
        }

        this.startAutoScroll();

        if (this.currentIndex !== 0) {
          this.currentIndex --;
        } else {
          this.currentIndex = this.list.length - 1;
        }
      // 切换至下一张图
      } else {
        if(this.bannerInfo.x % winWidth !== 0) {
          // 说明容器宽度不一样
          const x = this.bannerInfo.x / this.bannerInfo.width
          this.bannerInfo.width = winWidth;
          this.bannerInfo.x = (x-1)*winWidth
        } else {
          this.bannerInfo.x -= winWidth;
        }
        this.startAutoScroll();
        if (this.currentIndex !== this.list.length-1) {
          this.currentIndex ++;
        } else {
          this.currentIndex = 0;
        }
      }
    } else {
      const link = this.list[this.currentIndex].link;
      // 判断是否触发点击事件
      if(link && Date.now() - this.bannerInfo.startTime < 400 && Math.abs(this.bannerInfo.move) < 10) {
        window.open(link, '_blank')
      }
    }
    this.bannerInfo.startTime = 0;
    this.bannerInfo.move = 0;
    this.bannerInfo.start = 0;
    this.setSwiperMoving();
  }
  public onBannerMove(e: any) {
    if(!this.bannerInfo.active) {
      return;
    }
    const move = (e.clientX || (e.touches && e.touches[0].clientX)) - this.bannerInfo.start;
    this.bannerInfo.move = move;
  }
  public setSwiperMoving() {
    this.bannerInfo.transition = true;
    setTimeout(()=> {
      this.recoverPosition();
      this.bannerInfo.transition = false;
    },500)
  }
  // 是否需要复原位置
  public recoverPosition() {
    const lng = this.bannerList.length;
    if(this.list.length <= 1) {
      return;
    }
    if(this.bannerInfo.x === 0) {
      this.bannerInfo.x = this.bannerInfo.width * (lng - 2) * -1;
      return;
    }
    if(this.bannerInfo.x === this.bannerInfo.width * (lng - 1) * -1 ) {
      this.bannerInfo.x = this.bannerInfo.width * -1;
    }
  }
  // 下一个
  public swipeNext() {
    const width = this.bannerRef.clientWidth;
    let oldX = this.bannerInfo.x;
    // 如果容器宽度不一样
    if (this.bannerInfo.x % width !== 0) {
      oldX = this.bannerInfo.x/this.bannerInfo.width * width
      this.bannerInfo.width = width;
    }

    if(this.currentIndex < this.list.length - 1) {
      this.currentIndex ++;
    } else {
      this.currentIndex = 0;
    }
    this.bannerInfo.x = oldX - width;
    this.setSwiperMoving();
  }
  // 点击页数按钮
  public swipeChange(index: number) {
    if(this.currentIndex === index) {
      return;
    }
    this.currentIndex = index;
    this.setSwiperMoving();
    this.bannerInfo.x = (this.currentIndex + 1) * - this.bannerInfo.width;
    this.startAutoScroll();
  }
  public windowSizeChange() {
    if(this.bannerList.length <= 1) {
      return;
    }
    this.startAutoScroll()
    const width = this.bannerRef.clientWidth;
    if (!width || width === this.bannerInfo.width) {
      return;
    }
    this.bannerInfo.width = width;
    this.bannerInfo.x = (this.currentIndex + 1) * - width;
  }
}
