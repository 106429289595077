

























































import { Component, Vue } from 'vue-property-decorator';;
import { AppModule } from '@/store/modules/app';
import { ContactUsData } from '@/interface/common';
import { sendEmailApi } from '@/api/index';
import { isMobile } from '@/utils/validate';

@Component({
  name: 'contact',
})
export default class extends Vue {
  public themeName = "GEC Global Inquiry";
  public userEmail = "";
  public userName = "";
  public userQuestion = "";
  public errMsg = "";
  public successMsg = "";
  public submitData: ContactUsData = {
    keyCode: '',
    name: '',
    mobile:'',
    email:'',
    question: '',
    userGrade: '',
    studyCountry: '',
    studyYear: '',
    whatsappContact: '',
    messageHeader: '',
    recipientEmail: ''
  }

  get isMobile() {
    return isMobile();
  }
  public async submitInquiry() {
    let valid = true;
    this.errMsg = '';
    this.successMsg = '';
    if (valid) {
      if ((this.userName === undefined) || (this.userName === '')) {
        this.errMsg = this.$t('index.contactMissName') + '' || '';
        valid = false;
      }
    }
    if (valid) {
      if ((this.userEmail === undefined) || (this.userEmail === '')) {
        this.errMsg = this.$t('index.contactMissEmail') + '' || '';
        valid = false;
      } else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(this.userEmail) === false) {
          this.errMsg = this.$t('index.contactMissEmail') + '' || '';
          valid = false;
        }
      }
    }
    if (valid) {
      if ((this.userQuestion === undefined) || (this.userQuestion === '')) {
        this.errMsg = this.$t('index.contactMissQuestion') + '' || '';
        valid = false;
      }
    }
    if (valid === true) {
      this.submitData.keyCode = "gec";
      this.submitData.email = this.userEmail;
      this.submitData.mobile = "";
      this.submitData.name = this.userName;
      this.submitData.question = this.userQuestion;
      this.submitData.messageHeader = this.themeName;
      this.submitData.recipientEmail = 'apple.tam@geaworld.org';
      const ret = await sendEmailApi(this.submitData);
      if(ret.data.code === '1003') {
        this.successMsg = this.$t('index.submittedQuestReply') + '' || '';
        this.userEmail = '';
        this.userName = '';
        this.userQuestion = '';
      }
    }
  }
}
