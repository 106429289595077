






















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import BannerBox from './components/BannerBox.vue';
import { bannerData } from '@/static/data/banner';
import { courseData } from '@/static/data/course';
import { courseFeatureData } from '@/static/data/courseFeature';
import { introContent, offerContent, floorContent } from '@/static/data/index';
interface BannerItem {
  link: string,
  path: string,
  remark?: string
}
@Component({
  name: 'index',
  components: {
    BannerBox
  }
})
export default class extends Vue {
  public bannerList: BannerItem[] = []; // 首页banner
  public courseList: any[] = [];
  get getIndexSideImg() {
    return require('../../assets/image/common/indexSide-' + AppModule.language.toLowerCase() + '.png');
  }
  get introInfo() {
      return introContent.filter((v: any) => (v.lang ===  AppModule.language.toLowerCase()))[0].content;
  }
  get courseFeatureList() {
    return courseFeatureData.filter((v: any) => (v.lang ===  AppModule.language.toLowerCase()))[0].content;
  }
  get offerInfo() {
      return offerContent.filter((v: any) => (v.lang ===  AppModule.language.toLowerCase()))[0].content;
  }
  get floorInfo() {
      return floorContent.filter((v: any) => (v.lang ===  AppModule.language.toLowerCase()))[0].content;
  }
  public created() {
    this.bannerList = bannerData;
    this.courseList = courseData;
  }
  public goAllCourse() {
    window.location.href = '../CourseList';
  }
  public goCourse(item: any) {
    window.location.href = "../course/" + item.id;
  }
  public goApplication() {
    window.location.href = '../ApplicationProcedure';
  }
}
